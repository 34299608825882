var canvas = document.querySelector('.snow'),
    ctx = canvas.getContext('2d'),
    windowW = window.innerWidth,
    windowH = window.innerHeight,
    numFlakes = 100,
    flakes = [];

var flakeColors = [
    '255, 136, 67',
    '255, 114, 114',
    '227, 45, 136',
    '102, 87, 183',
    '76, 91, 231'
];

function randomColor() {
    var whiteMix = 20;
    var randIndex = Math.floor(Math.random() * (whiteMix + flakeColors.length));
    if(randIndex < flakeColors.length) {
        return flakeColors[randIndex];
    } else {
        return '255, 255, 255';
    }
}


function randomBetween(min, max, round) {
    var num = Math.random() * (max - min + 1) + min;

    if (round) {
        return Math.floor(num);
    } else {
        return num;
    }
}

function Flake(x, y) {
    var maxWeight = 5,
        maxSpeed = 2;

    this.x = x;
    this.y = y;
    this.r = randomBetween(0, 1);
    this.a = randomBetween(0, Math.PI);
    this.aStep = 0.01;


    this.weight = randomBetween(2, maxWeight);
    //this.alpha = randomBetween(2, maxWeight);
    this.alpha = Math.random() * .4;
    this.color = randomColor();
    this.speed = (this.weight / maxWeight) * maxSpeed;

    this.update = function () {
        this.x += Math.cos(this.a) * this.r;
        this.a += this.aStep;

        this.y += this.speed;
    };

}

function scaleCanvas() {
    canvas.width = windowW;
    canvas.height = windowH;
}

function loop() {
    var i = flakes.length,
        z,
        dist,
        flakeA,
        flakeB;

    // clear canvas
    ctx.save();
    ctx.setTransform(1, 0, 0, 1, 0, 0);
    ctx.clearRect(0, 0, windowW, windowH);
    ctx.restore();

    // loop of hell
    while (i--) {

        flakeA = flakes[i];
        flakeA.update();

        ctx.beginPath();
        ctx.arc(flakeA.x, flakeA.y, flakeA.weight, 0, 2 * Math.PI, false);
        ctx.fillStyle = 'rgba('+ flakeA.color + ', ' + flakeA.alpha + ')';
        ctx.fill();

        if (flakeA.y >= windowH) {
            flakeA.y = -flakeA.weight;
        }
    }

    requestAnimationFrame(loop);
}


function snowInit() {
    var i = numFlakes,
        flake,
        x,
        y;

    while (i--) {
        x = randomBetween(0, windowW, true);
        y = randomBetween(0, windowH, true);


        flake = new Flake(x, y);
        flakes.push(flake);
    }

    scaleCanvas();
    loop();

    window.addEventListener('resize', function () {
        windowW = window.innerWidth;
        windowH = window.innerHeight;
        scaleCanvas();

        for (var i = 0; i < flakes.length; i++) {
            x = randomBetween(0, windowW, true);
            y = randomBetween(0, windowH, true);
            flakes[i].x = x;
            flakes[i].y = y;
        }
    });
}

function distanceBetween(vector1, vector2) {
    var dx = vector2.x - vector1.x,
        dy = vector2.y - vector1.y;

    return Math.sqrt(dx * dx + dy * dy);
}