(function ($) {
    var buttonMessages = [
        'Wow, Danke! 👌',
        'Das wünsche ich Dir auch! 🎄',
        'Danke! Bis im nächsten Jahr! 🎉',
        'Ich wünsche dir auch einen guten Rutsch ins 2024! 🍾',
        'Das freut mich! Danke! ☺️',
        'Danke gleichfalls! 🤗',
        'Danke! Let it snow! 🌨',
        'Ich wünsche dir auch eine erholsame Zeit! 🛀'
    ];

    const threshold = 100;
    var $page;

    function isMobile() {
        var breakpoint = window.getComputedStyle($('.page')[0], ':after').content.replace(/\"/g, '');
        return breakpoint === 'xs' || breakpoint === 'sm' || breakpoint === 'md';
    }

    function nextText() {
        if ($page.hasClass('page--0')) {
            $page.removeClass('page--0').addClass('page--10');
        } else {
            for (var i = 1; i <= 10; i++) {
                if ($page.hasClass('page--' + i)) {
                    $page.removeClass('page--' + i).addClass('page--' + (i - 1));
                    break;
                }
            }
        }
    }

    function changeCardStates() {
        var $prevCard = $('.card--current');
        var $currentCard = $('.card--next');
        var $nextCard = null;
        if ($currentCard.prev().length) {
            $nextCard = $currentCard.prev();
        } else {
            $nextCard = $('.card--10');
        }

        $prevCard.removeClass('card--current');
        $currentCard.removeClass('card--next').addClass('card--current');
        $nextCard.addClass('card--next');
    }

    function changeZIndex() {
        $('.card').each(function () {
            if ($(this).hasClass('card--z-10')) {
                $(this).removeClass('card--z-10').addClass('card--z-0');
            } else {
                for (var i = 0; i < 10; i++) {
                    if ($(this).hasClass('card--z-' + i)) {
                        var nexIndex = i + 1;
                        $(this).removeClass('card--z-' + i).addClass('card--z-' + nexIndex);
                        break;
                    }
                }
            }
        });
    }

    function handleMouseUp(event) {
        if (!isMobile()) {
            $page.addClass('page--transformed');
            var $card = $(event.currentTarget);
            if (!$card.hasClass('card--swiperight') && !$card.hasClass('card--swipeleft')) {
                $card.addClass('card--swipeleft');
            }
        }
    }

    function handlePan(event) {
        $page.addClass('page--transformed');
        var $card = $(event.currentTarget);

        var deltaX = event.originalEvent.gesture.deltaX;
        var deltaXAbs = Math.abs(deltaX);

        var rotate = window.getComputedStyle($card[0], ':before').content.replace(/\"/g, '');

        var transform = 'rotate(' + rotate + 'deg)';
        if (deltaXAbs >= threshold || event.originalEvent.gesture.isFinal) {
            if (deltaX > 0) {
                $card.addClass('card--swiperight');
            } else {
                $card.addClass('card--swipeleft');
            }
        } else {
            transform = 'translateX(' + deltaX + 'px) ' + transform;
        }

        $card.css({
            '-webkit-transform': transform,
            '-moz-transform': transform,
            '-ms-transform': transform,
            '-o-transform': transform,
            'transform': transform
        });
    }

    function typeWriter(element, i, message, speed) {
        if (i < message.length) {
            if (i === 0) {
                element.innerHTML = message.charAt(i);
            } else {
                element.innerHTML += message.charAt(i);
            }
            i++;
            setTimeout(function () {
                typeWriter(element, i, message, speed);
            }, speed);
        }
    }

    $(document).ready(function () {
        $page = $('.page');
        $page.addClass('page--transform');

        var $cards = $('.cards');

        var $hammer = $cards.hammer({domEvents: true});
        $hammer.data('hammer').get('pan').set({direction: Hammer.DIRECTION_HORIZONTAL});
        $hammer.on("panleft panright", '.card--current', handlePan);
        $cards.on('mouseup', '.card--current', handleMouseUp);

        $cards.on('webkitAnimationEnd oanimationend msAnimationEnd animationend', '.card', function () {
            changeCardStates();
            changeZIndex();
            nextText();
            $(this).removeClass('card--swipeleft').removeClass('card--swiperight');
        });

        $cards.on('transitionend webkitTransitionEnd oTransitionEnd', '.card', function (event) {
            if (!$page.hasClass('page--transformed')) {
                $page.addClass('page--transformed');
                $page.addClass('page--10');

                $('.cards-container__heading').css('transition-delay', '0s');
            }
            $(this).css('transition-duration', '0s');
        });

        $('.button--js').on('click', function (event) {
            event.preventDefault();

            if (!$(this).hasClass('button-container__button--disabled')) {

                var i = 0;
                var message = buttonMessages[Math.floor(Math.random() * buttonMessages.length)];
                var speed = 50;
                var self = this;

                $(this).attr('disabled', true);
                $(this).addClass('button-container__button--disabled text__italic');

                typeWriter(this, i, message, speed);


                var name = $(this).data('name');
                $.ajax('/message.php', {
                    method: 'post',
                    data: {name: name}
                });

            }
        });

        snowInit();
    });

})(jQuery); // Fully reference jQuery after this point.
