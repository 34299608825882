var flakesContainer          = document.querySelector('.snowflakes');
var snowToggle               = document.querySelector('.toggle');
var snowFloor                = document.querySelector('.snow-floor');
var snowActive               = false;
var originPosition           = { x: 0, y: 0 };
var lastFlakePosition        = originPosition;
var minDistanceBetweenFlakes = 30;
var flakeLivespanInMs        = 6000;
var flakeCounter             = flakeCounterWatcher(0);

var dotSizes      = [0.5, 0.6, 0.8, 0.4, 0.7];
var dotAnimations = ['fall-1', 'fall-2', 'fall-3'];

snowToggle.addEventListener('change', function(){
    snowActive = !snowActive;
    canvas.classList.toggle('snow--weak');

    if(!snowActive) {
        flakeCounter.value = 0;
    }
});

function updateLastFlakePosition(position) {
    lastFlakePosition = position;
}

function calcDistance(a, b) {
    var diffX = b.x - a.x;
    var diffY = b.y - a.y;

    return Math.sqrt(Math.pow(diffX, 2) + Math.pow(diffY, 2));
}

function randomItem(items) {
    return items[Math.floor(Math.random() * items.length)];
}

function removeElement(element, delay) {
    setTimeout(function(){
        flakesContainer.removeChild(element);
    }, delay);
}

function setDotSize(element, sizeRem) {
    element.style.width  = sizeRem+"rem";
    element.style.height = sizeRem+"rem";
}

function createFlake(position) {
    var dot           = document.createElement("span");
    dot.className     = "flake";
    dot.style.left    = position.x+"px";
    dot.style.top     = position.y+"px";
    dot.style.opacity = Math.random() + .3;

    setDotSize(dot, randomItem(dotSizes));
    dot.style.setProperty('--dot-color', randomColor());
    dot.style.animationName = randomItem(dotAnimations);

    flakesContainer.appendChild(dot);

    //update snow-floor when flake is created
    flakeCounter.value++;

    removeElement(dot, flakeLivespanInMs);
}

function flakeCounterWatcher(initValue) {
    var value      = initValue;
    var timeoutIds = [];

    return {
        get value() {
            return value;
        },
        set value(newValue) {
            value = newValue;

            if (newValue === 0) {
                timeoutIds.forEach(function (timeoutId) {
                    clearTimeout(timeoutId);
                });

                timeoutIds = [];
                snowFloor.style.height = "0";
            } else {
                var newTimeoutId = setTimeout(function() {
                    snowFloor.style.height = newValue + "px";
                }, 2000);

                timeoutIds.push(newTimeoutId);
            }
        }
    };
}

function handleOnMove(e) {
    var mousePosition     = { x: e.clientX, y: e.clientY };
    var hasMovedFarEnough = calcDistance(lastFlakePosition, mousePosition) >= minDistanceBetweenFlakes;
    var isInCreateArea    = mousePosition.y < window.innerHeight - snowFloor.clientHeight;


    if(hasMovedFarEnough && isInCreateArea) {
        createFlake(mousePosition);
        updateLastFlakePosition(mousePosition);
    }
}

window.onmousemove = function(e) {
    if(snowActive) {
        handleOnMove(e);
    }
};

document.body.onmouseleave = function(){
    updateLastFlakePosition(originPosition);
};